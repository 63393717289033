<template>
    <b-nav-item-dropdown
        right
        toggle-class="d-flex align-items-center dropdown-user-link"
        class="dropdown-user"
    >
        <template #button-content>
            <div class="d-sm-flex d-none user-nav">
                <p class="user-name font-weight-bolder mb-0">
                    {{ userData.name || userData.email }}
                </p>
                <span class="user-status">{{ userData.role }}</span>
            </div>
            <b-avatar
                size="40"
                :src="userData.avatar"
                variant="light-primary"
                badge
                class="badge-minimal"
                badge-variant="success"
            >
                <feather-icon
                    v-if="!userData.fullName"
                    icon="UserIcon"
                    size="22"
                />
            </b-avatar>
        </template>

        <b-dropdown-item
            link-class="d-flex align-items-center"
            @click="logout"
        >
            <feather-icon
                size="16"
                icon="LogOutIcon"
                class="mr-50"
            />
            <span>Logout</span>
        </b-dropdown-item></b-nav-item-dropdown>
</template>

<script>
    import {
        BNavItemDropdown, BDropdownItem, BAvatar
    } from 'bootstrap-vue'
    import { avatarText } from '@core/utils/filter'

    export default {
        components: {
            BNavItemDropdown,
            BDropdownItem,
            BAvatar
        },
        data() {
            return {
                userData: this.$store.state.app.userData,
                avatarText
            }
        },
        methods: {
            logout() {
                // Remove userData from localStorage
                // ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
                //localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
                //localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)

                // Remove userData from localStorage
                localStorage.removeItem('userData')

                const thisIns = this
                thisIns.$store.dispatch('app/setLoginStatus', false)
                thisIns.$store.dispatch('app/updateUserData', {})
                this.$http.get('/api/management/v1/logout/')
                    .then(function() {
                        thisIns.$router.push('/login')
                    }).catch(function(error) {
                        thisIns.$printError(error.response.data)
                    })
            }
        }
    }
</script>
